import React, { useState } from "react";
import Layout from '../components/layout'
import CssBaseline from '@mui/material/CssBaseline';
import { Button, Box, Input, Grid, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Select, MenuItem, FormGroup, Container, Snackbar, Alert } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { navigate } from "gatsby"

const theme = createTheme({
  components: {
    TextField: {
      styleOverrides: {
        root: {
          'margin-top': '1rem'
        },
      },
    }
  }
});

const defaultValues = {
  name: '',
  phone: '',
  email: '',
  island: '',
  referer: '',
  comments: '',
  dayToCall: 'monday',
};

const NewOfficialPage = () => {

  const [formValues, setFormValues] = useState(defaultValues);

  const [open, setOpen] = React.useState(false);

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formValues);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formValues)
    };

    fetch('https://l6zjnygwc2.execute-api.us-west-2.amazonaws.com/referee-form', requestOptions)
      .then(response => {
        if (response.status === 200) {
          navigate('/thankyou');
        } else {
          console.log('Problem posting form data');
          console.log(response)
          setOpen(true);
        }
      })
      .catch(error => {
        console.log('Problem posting form data');
        console.log(error)
        setOpen(true);
      });

  };

  return (
    <ThemeProvider theme={theme}>
    <Layout pageTitle="Home Page">
      <Container component="main" maxWidth="xs">

      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div>
          <h3>Fill out the web form below, and you will be contacted about how to get started as a Football Official in Hawaii.</h3>
        </div>
        <Box component="form" onSubmit={handleSubmit} sx={
          {
            mt: 1,
          }
        }>
            <TextField
              fullWidth
              autoFocus
              id="name-input"
              name="name"
              label="Name"
              type="text"
              value={formValues.name}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              id="phone-input"
              name="phone"
              label="Phone"
              type="text"
              value={formValues.phone}
              onChange={handleInputChange}
              required
            />
          <TextField
            fullWidth
            id="email-input"
            name="email"
            label="Email"
            type="text"
            value={formValues.email}
            onChange={handleInputChange}
            required
          />
          <TextField
            fullWidth
            id="island-input"
            name="island"
            label="Oahu, Kauai, Maui or Big Island"
            type="text"
            value={formValues.island}
            onChange={handleInputChange}
            required
          />
          <TextField
            fullWidth
            id="referer-input"
            name="referer"
            label="How did you hear about this site?"
            type="text"
            value={formValues.referer}
            onChange={handleInputChange}
          />
          <TextField
            multiline
            fullWidth
            maxRows={8}
            minRows={4}
            height={4}
            id="comments-input"
            name="comments"
            label="Comments - a little about yourself"
            type="text"
            value={formValues.comments}
            onChange={handleInputChange}
          />

          <FormControl fullWidth>
                <FormLabel>Best Day to Call</FormLabel>
                <RadioGroup
                  name="dayToCall"
                  value={formValues.dayToCall}
                  onChange={handleInputChange}
                  row
                >
                  <FormControlLabel
                    key="monday"
                    value="monday"
                    control={<Radio size="small" />}
                    label="Monday"
                  />
                  <FormControlLabel
                    key="tuesday"
                    value="tuesday"
                    control={<Radio size="small" />}
                    label="Tuesday"
                  />
                  <FormControlLabel
                    key="wedensday"
                    value="wedensday"
                    control={<Radio size="small" />}
                    label="Wedensday"
                  />
                  <FormControlLabel
                    key="thursday"
                    value="thursday"
                    control={<Radio size="small" />}
                    label="Thursday"
                  />
                  <FormControlLabel
                    key="friday"
                    value="friday"
                    control={<Radio size="small" />}
                    label="Friday"
                  />
                  <FormControlLabel
                    key="saturday"
                    value="saturday"
                    control={<Radio size="small" />}
                    label="Saturday"
                  />
                  <FormControlLabel
                    key="sunday"
                    value="sunday"
                    control={<Radio size="small" />}
                    label="Sunday"
                  />
                </RadioGroup>
              </FormControl>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
        </Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose}>
          <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
            There was a problem submitting your information. Please check your information and try again.
          </Alert>
        </Snackbar>
      </Box>
      </Container>

    </Layout>
    </ThemeProvider>
  );
};

export default NewOfficialPage;
